/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, owlCarousel, NiceSelect, customGoogleMap, InfiniteAjaxScroll) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body         = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose  = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
        ;


        /*
		|
		| Cookie Manager
		|-----------------
		*/
        new CookieManager($cookieBanner, {
            name: 'cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accepted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        $body.on('loaderEnd', () => console.log('ended'))

        /* HP carousel */
        if ($('.block-carousel-background .owl-carousel').length > 0) {
            var owl = $('.block-carousel-background .owl-carousel');

            owl.on('initialized.owl.carousel', function(event) {
                $('.block-carousel-background').css({opacity: 1});
            });

            if ($('.block-carousel-background-item').length > 1) {
                owl.owlCarousel({
                    loop:true,
                    margin:30,
                    nav:false,
                    dots:true,
                    items: 1,
                    autoplay:true,
                    autoplayTimeout:5000,
                    autoplayHoverPause:true,
                    smartSpeed: 2000,
                    animateOut: 'fadeOut',
                    animateIn: 'fadeIn',
                });
            } else {
                owl.owlCarousel({
                    loop:false,
                    margin:30,
                    nav:false,
                    dots:true,
                    items: 1
                });
            }


        }

        if ($('.block-carousel-experience .owl-carousel').length > 0) {
            $('.block-carousel-experience .owl-carousel').owlCarousel({
                loop:true,
                margin:30,
                nav:true,
                dots:false,
                items: 1,
                navText:["<div class='nav-btn prev-slide'><i class=\"fal fa-chevron-left\"></i></div>","<div class='nav-btn next-slide'><i class=\"fal fa-chevron-right\"></i></div>"],
                responsive:{
                    576:{
                        items: 1
                    },
                    768:{
                        items: 2
                    },
                    992:{
                        items: 3
                    }
                },
                autoplay:true,
                autoplayTimeout:5000,
                autoplayHoverPause:true,
                smartSpeed: 2000
            });
        }

        if ($('.block-carousel-inspiration .owl-carousel').length > 0) {
            $('.block-carousel-inspiration .owl-carousel').owlCarousel({
                loop:true,
                margin:30,
                nav:true,
                dots:false,
                items: 1,
                navText:["","<div class=\"nav-btn next-slide double-line link-more\">Inspiration suivante <i class=\"far fa-long-arrow-right\"></i></div>"],
                autoplay:true,
                autoplayTimeout:5000,
                autoplayHoverPause:true,
                smartSpeed: 2000
            });
        }

        var foundin = $('.block-contact-form select option:contains("optgroup-")');
        $.each(foundin, function(value){
            var updated = $(this).val().replace('optgroup-','');
            $(this).nextUntil('option:contains("endoptgroup")').wrapAll('<optgroup label="'+updated+'" data-i="'+(value+1)+'"></optgroup>');
        });
        $('.block-contact-form select option:contains("optgroup-")').remove();
        $('.block-contact-form select option:contains("endoptgroup")').remove();

        $('.block-contact-form select').each(function(value) {
            $(this).find('option:eq(0)').attr('disabled', 'disabled').attr('selected', 'seleceted').attr('hidden', 'hidden');
        });

        $('.search-destination select').niceSelect();
        $('.block-contact-form select').niceSelect();

        if ($('#map').length > 0) {
            $('#map').each(function () {
                var $map = $(this);

                var map = new customGoogleMap($map, {
                    'markers': $map.find('.marker'),
                    'zoom': 5
                });
            });
        }

        $('<button type="submit" class="wpcf7-form-control wpcf7-submit footer-newsletter-button-send">'+($('.footer-newsletter-button-hidden').html())+' Je m\'abonne</button>').insertAfter('#newsletter-submit');
        $('#newsletter-submit').remove();

        $('.search-btn-wrap').on('click', function() {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $('.search-form-wrap').slideUp();
                $('.search-btn-search').show();
                $('.search-btn-cross').hide();
            } else {
                $(this).addClass('active');
                $('.search-form-wrap').slideDown();
                $('.search-btn-search').hide();
                $('.search-btn-cross').show();
            }
        });

        let ias;

        if ($('.posts').length) {
            initAjaxscroll();
        }

        $('.block-accomodation-filter-btn').on('click', function() {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
        });

        $('.block-accomodation-filter-box-submit').on('click', function() {
            var allValues = [];
            var element = $(this);

            // Récupération des filtres
            $('.block-accomodation-filter-box input:checked').each(function(i, n) {
                allValues[i] = $(n).val();
            });

            // Fermeture de la box
            element.closest('.block-accomodation-filter-box').prev().removeClass('active');

            // Suppression des posts existants
            $('.posts .post').remove();
            // Spinner
            $('.trigger').css('opacity', 0);
            $('.spinner').css('opacity', 1);

            // Appel AJAX nouveaux posts
            $.ajax({
                url: $('.posts').data('url'),
                data: {filters: allValues},
                success: function(result) {
                    var posts = $(result).find('.posts-wrap').html();
                    $('.posts-wrap').html(posts);
                    initAjaxscroll();
                }
            });

        });

        function initAjaxscroll() {
            ias = new InfiniteAjaxScroll('.posts', {
                item: '.post',
                next: '.next',
                pagination: '#pagination',
                spinner: '.spinner',
                logger: false,
                loadOnScroll: false,
                trigger: '.trigger'
            });
            ias.on('appended', handler);
        }

        function handler(event) {
        }

        $('.block-hotel-gm-filter-map').on('click', function() {
            $('.block-hotel-gm-filter-gallery').removeClass('active');
            $(this).addClass('active');
            $('.block-hotel-gm-map').show();
            $('.block-hotel-gm-gallery').hide();
        });

        $('.block-hotel-gm-filter-gallery').on('click', function() {
            $('.block-hotel-gm-filter-map').removeClass('active');
            $(this).addClass('active');
            $('.block-hotel-gm-gallery').show();
            $('.block-hotel-gm-map').hide();
        });

        $('.block-qr-question').on('click', function(e) {
            e.preventDefault();

            if (!$(this).hasClass('active')) {
                $('.block-qr-question').removeClass('active');
                $('.block-qr-answer').slideUp();
                $(this).addClass('active');
                $(this).next().slideDown();
            } else {
                $(this).removeClass('active');
                $(this).next().slideUp();
            }
        });

        $('.multistep-cf7-next').on('click', function() {
            setTimeout(function() {
                if ($('.wpcf7_check_tab').val() == 2) {
                    $('.block-contact-form-step-first').removeClass('active');
                    $('.block-contact-form-step-second').addClass('active');
                }
            }, 3000);
        });

        $('.search-select').on('change', function() {
            location.href = $(this).val();
        });

        $('[data-fancybox="gallery"]').fancybox({
            loop: true,
            infobar: false,
            buttons: [
                "close"
            ]
        });

    }
}
