/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Menu, AOS) => {

		const
			$body             = $('body'),
			scrollAreas       = document.querySelectorAll('.scroll-area'),
			$menuWrapper      = $('#mobile-menu'),
			$menuButton       = $('#header .btn-menu'),
			$menuBars         = $menuButton.find('.item-burger > span'),
			$menuClose        = $menuWrapper.find('.item-close'),
			$menuStaggerItems = $menuWrapper.find('[data-stagger-item]')
		;

		const menu = new Menu($menuWrapper, $menuButton, {
			reverseTimeScale: 2
		});

		menu.menuTimeline
			.to($menuBars.eq(1), 0.3, { autoAlpha: 0 }, 'start')
			.to($menuBars.eq(0), 0.5, { x: 0, y: 5, rotation: 45, ease: Power1.easeOut }, 'start')
			.to($menuBars.eq(2), 0.5, { x: 0, y: -5, rotation: -45, ease: Power1.easeOut }, 'start')
			//.to($menuButton, 0.5, { y: -60, ease: Power1.easeOut }, 'start')
			.from($menuWrapper, 0.5, { autoAlpha: 0, ease: Power1.easeOut }, 'start+=0.3')
			.staggerFrom($menuStaggerItems, 0.6, { autoAlpha: 0, x: -20, ease: Power1.easeOut }, '0.1', '+=0')

		menu.init();

		$('#mobile-menu .open-sub').on('click', function() {
			if ($(this).hasClass('active')) {
				$(this).next().css({opacity: 0}).hide();
				$(this).removeClass('active');
			} else {
				$('#mobile-menu .nav-drop').css({opacity: 0}).hide();
				$('#mobile-menu .open-sub').removeClass('active');
				$(this).next().show().css({opacity: 1});
				$(this).addClass('active');
			}
		});

		AOS.init({
			duration: 1000
		});

		// Back to top
		$(window).scroll(function() {
			if ($(window).scrollTop() > 300) {
				$('#back-to-top').addClass('show');
			} else {
				$('#back-to-top').removeClass('show');
			}
		});

		$('#back-to-top').on('click', function(e) {
			e.preventDefault();
			$('html, body').animate({scrollTop:0}, '300');
		});

		$(".wrap-menu").sticky({topSpacing:0});

		$('.js-auto-height-1').matchHeight();
		$('.js-auto-height-2').matchHeight();
		$('.js-auto-height-3').matchHeight();
		$('.js-auto-height-4').matchHeight();

	}
}
