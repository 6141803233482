/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
import { TweenMax } from "gsap/TweenMax";
import owlCarousel from 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import '@fortawesome/fontawesome-pro/js/all.min.js'
import '@fortawesome/fontawesome-pro/css/all.min.css';
import '@fortawesome/fontawesome-pro/css/light.min.css';
import InfiniteAjaxScroll from '@webcreate/infinite-ajax-scroll';
import 'aos/dist/aos.css';
import AOS from 'aos';
import Fancybox from '@fancyapps/fancybox';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import 'jquery-match-height/jquery.matchHeight';
import 'bootstrap';


/*
|
| Importing Components
|-----------------------
*/
import CookieManager from '@components/cookie-manager';
import * as app from '@components/global.js';
import NiceSelect from '@components/jquery.nice-select.js';
import customGoogleMap from '@components/custom-google-map.js';
import Menu from '@components/menu.js';
import '@components/jquery.sticky.js';

/*
|
| Importing Utils
|-------------------
*/
import '@utils/fa';
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import main from '@pages/main.js';
import animations from '@pages/animations.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
	{
        'file': main,
        'dependencies': [app, CookieManager, owlCarousel, NiceSelect, customGoogleMap, InfiniteAjaxScroll]
    },
    {
        'file': animations,
        'dependencies': [app, Menu, AOS]
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

